import React, { useState } from 'react';
function Blog() {

  return (
    <div className="blog">
        blog
    </div>
  );
}

export default Blog;